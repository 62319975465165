





































































































































































































































import Vue from 'vue'
import {
  mapActions,
  mapState
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import Schedule from '@/components/Schedule/Schedule.vue'
import { TheMask } from 'vue-the-mask'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'
import ValidatedBFormMaskedInput from '@/components/ui/ValidatedBFormMaskedInput.vue'
import ModalPickAddress from '@/components/ModalPickAddress/ModalPickAddress.vue'
import { PGTProfile } from '@/store/modules/pgt/types'
import { cloneDeep } from '@/utils/functions'

export default Vue.extend({
  name: 'ProfileDetail',

  components: {
    TheMask,
    Loading,
    ValidatedBFormInput,
    ValidatedBFormMaskedInput,
    Schedule,
    ModalPickAddress
  },

  data () {
    return {
      saving: false,
      localProfile: null as PGTProfile,
      scheduleIsValid: true,
      deletedBusinessWorkTimesIds: [],
      isShownAddressModal: false,
      changingAddressKey: '',
      changingLocation: null,
      contactPhoneData: {
        valid: true
      }
    }
  },

  computed: {
    ...mapState('common', [
      'businessCategories',
      'loading'
    ]),

    ...mapState('pgt', [
      'pgtProfile'
    ])
  },

  watch: {
    pgtProfile () {
      this.localProfile = cloneDeep(this.pgtProfile)
    }
  },

  mounted () {
    if (this.pgtProfile.id) {
      this.localProfile = cloneDeep(this.pgtProfile)
    }
  },

  methods: {
    ...mapActions('profiles', [
      'deleteBusinessWorkTimes',
      'updateDeliveryLocation'
    ]),
    ...mapActions('pgt', [
      'updatePGTProfile'
    ]),

    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid || !this.scheduleIsValid || !this.contactPhoneData?.valid) return

      this.handleUpdateProfile()
    },

    async handleUpdateProfile () {
      const schedule = this.$refs.schedule.returnSchdule()
      if (schedule.weekends.length) {
        await this.removeBusinessWorkTimes(schedule.weekends)
      }
      await this.handleUpdateDeliveryLocation()

      await this.updateProfileData()
    },

    async handleUpdateDeliveryLocation () {
      try {
        this.saving = true
        await this.updateDeliveryLocation({
          id: this.localProfile.business_profile.id,
          byField: 'business_profile_id',
          data: this.localProfile.business_profile.location
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.saving = false
      }
    },

    async removeBusinessWorkTimes (workTimesIds) {
      try {
        this.saving = true
        await this.deleteBusinessWorkTimes({
          profileId: this.localProfile.business_profile.id,
          workTimesIds
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.saving = false
      }
    },

    async updateProfileData () {
      const data = cloneDeep(this.localProfile)
      data.business_profile.contact_phone = data.business_profile.contact_phone.replaceAll(/[ +]/g, '')
      data.phone = data.phone.replaceAll(/[ +]/g, '')
      this.saving = true
      const result = await this.updatePGTProfile(data)

      if (result.status < 200 || result.status >= 300) {
        this.$toast.error(result.data.detail.message)
        this.saving = false
        return
      }
      this.$emit('refresh')
      this.$toast.success('Данные обновлены')

      this.saving = false
    },

    handleSaveAddress (location) {
      this.localProfile.business_profile[this.changingAddressKey] = location
      this.isShownAddressModal = false
    },
    handleCloseAddress () {
      this.changingAddressKey = ''
      this.isShownAddressModal = false
    },
    handleOpenAddress (key) {
      this.changingLocation = this.localProfile.business_profile[key]
      this.changingAddressKey = key
      this.isShownAddressModal = true
    },
    handleChangedSchedule (schedule) {
      this.localProfile.business_profile.work_time_schedule = schedule
    },

    validateContactPhone (...data) {
      const phoneObject = data.length > 1 ? data[1] : data[0]
      const number = data.length > 1 ? data[0] : ''
      this.contactPhoneData = { ...phoneObject, valid: phoneObject.valid || true }
      if (phoneObject.number && phoneObject.number.match(/[^\d+\s]/)) {
        this.contactPhoneData.valid = false
      }
      if (number && number.match(/[^\d+\s]/)) {
        this.contactPhoneData.valid = false
      }
    }
  }
})
